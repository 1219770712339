.about {
  background: #fffaf3;
}
.about .content {
  /* padding: 0 80px; */
}
.about .content h3 {
  font-weight: 400;
  font-size: 34px;
  color: #5f5950;
}

@media (min-width: 768px) {
  .about .content h3 {
    text-align: center;
    justify-content: center;
    align-content: center;
  }
}
.about .content h4 {
  font-size: 20px;
  font-weight: 700;
  margin-top: 5px;
}
.about .content p {
  font-size: 15px;
  color: #848484;
}
.about .content ul {
  list-style: none;
  padding: 0;
}
.about .content ul li + li {
  margin-top: 15px;
}
.about .content ul li {
  position: relative;
  padding-left: 26px;
}
.about .content ul i {
  font-size: 22px;
  color: #f76cc6;
  position: absolute;
  left: 0;
  top: -4px;
}
.about .content p:last-child {
  margin-bottom: 0;
}
.about .video-box {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  min-height: 400px;
  position: relative;
}
.about .play-btn {
  width: 94px;
  height: 94px;
  background: radial-gradient(#f76cc6 50%, rgba(255, 176, 59, 0.4) 52%);
  border-radius: 50%;
  display: block;
  position: absolute;
  left: calc(50% - 47px);
  top: calc(50% - 47px);
  overflow: hidden;
}
.about .play-btn::after {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-40%) translateY(-50%);
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #fff;
  z-index: 100;
  transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.about .play-btn::before {
  content: '';
  position: absolute;
  width: 120px;
  height: 120px;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation: pulsate-btn 2s;
  animation: pulsate-btn 2s;
  -webkit-animation-direction: forwards;
  animation-direction: forwards;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: steps;
  animation-timing-function: steps;
  opacity: 1;
  border-radius: 50%;
  border: 5px solid rgba(255, 176, 59, 0.7);
  top: -15%;
  left: -15%;
  background: rgba(198, 16, 0, 0);
}
.about .play-btn:hover::after {
  border-left: 15px solid #f76cc6;
  transform: scale(20);
}
.about .play-btn:hover::before {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-40%) translateY(-50%);
  width: 0;
  height: 0;
  border: none;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #fff;
  z-index: 200;
  -webkit-animation: none;
  animation: none;
  border-radius: 0;
}
@media (max-width: 1024px) {
  .about .content,
  .about .accordion-list {
    padding-left: 0;
    padding-right: 0;
  }
}
@media (max-width: 992px) {
  .about .content {
    padding-top: 30px;
  }
  .about .accordion-list {
    padding-bottom: 30px;
  }
}

@-webkit-keyframes pulsate-btn {
  0% {
    transform: scale(0.6, 0.6);
    opacity: 1;
  }
  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}

@keyframes pulsate-btn {
  0% {
    transform: scale(0.6, 0.6);
    opacity: 1;
  }
  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}

.about .makeup-artist {
  font-size: 24px;
  font-weight: 600;
  padding: 0;
  margin: 20px 0;
  color: #6c665c;
}
