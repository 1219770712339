#header {
  top: 0px;
  height: 100px;
  z-index: 997;
  transition: all 0.5s;
  padding: 10px 0;
  background: rgba(249, 250, 251, 1);

  -webkit-box-shadow: 0 8px 6px -6px black;
  -moz-box-shadow: 0 8px 6px -6px black;
  box-shadow: 0px 8px 6px -10px gray;
}

#header .logo img {
  margin-top: 0px;
  max-height: 90px;
}

