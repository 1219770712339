#services .section-title p {
  font-size: 1.2rem;
}

.chefs {
  background: url('./../assets/images/services/2.jpg') center center no-repeat;
  background-size: cover;
  /* padding: 60px 0; */
  position: relative;
}
.chefs::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.6);
  z-index: 9;
}
.chefs .container,
.chefs .container-fluid {
  position: relative;
  z-index: 10;
}

.chefs .section-title,
.chefs .section-title h2 {
  color: #433f39;
}
.chefs .member {
  text-align: center;
  margin-bottom: 80px;
  position: relative;
}
.chefs .member .pic {
  overflow: hidden;
}
.chefs .member .member-info {
  position: relative;
  margin: -40px 20px 0px 20px;
  background: #fff;
  padding: 20px 0;
  color: #433f39;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  overflow: visible;
  transition: max-height 0.5s ease-in-out;
}
.chefs .member h3 {
  font-weight: 500;
  margin: 0px 10px 10px 10px;
  color: #433f39;
  position: relative;
  padding-bottom: 10px;
  text-transform: uppercase;
}

.chefs .member span.price {
  /* font-style: italic; */
  display: block;
  /* font-size: 13px; */

  font-size: 26px;
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  margin-bottom: 10px;
  /* border-bottom: 2px solid ; */
}

.chefs .member span.price::after {
  content: '';
  position: absolute;
  display: block;
  width: 80px;
  height: 2px;
  background: #f76cc6;
  /* bottom: 0; */
  left: calc(50% - 40px);
}

.chefs .member small.additional-charges {
  display: block;
  margin-bottom: 20px;
}

.chefs .member small.discount {
  display: block;
  margin-bottom: 20px;
  font-size: 1rem;
  color: #f76cc6;
  font-weight: bolder;
}

.chefs .member .social {
  margin-top: 15px;
}
.chefs .member .social a {
  transition: color 0.3s;
  color: #7a7368;
}
.chefs .member .social a:hover {
  color: #f76cc6;
}
.chefs .member .social i {
  font-size: 16px;
  margin: 0 2px;
}
@media (max-width: 992px) {
  .chefs .member {
    margin-bottom: 110px;
  }
}
@media (min-width: 1024px) {
  .chefs {
    background-attachment: fixed;
  }
}

.chefs .member ul {
  list-style: none;
  padding: 0;
  text-align: left;
}
.chefs .member ul li {
  padding-bottom: 10px;
}
.chefs .member ul i {
  font-size: 20px;
  padding-right: 4px;
  color: #f76cc6;
}
