.php-email-form {
  width: 100%;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.12);
  padding: 30px;
  background: #fff;
}
.php-email-form .form-group {
  padding-bottom: 8px;
}
.php-email-form p.error-message {
  color: #963d5a;
  font-size: 13px;
}
.php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}
.php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}
.php-email-form .loading:before {
  content: '';
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
}
.php-email-form input,
.php-email-form textarea {
  border-radius: 10px;
  box-shadow: none;
  font-size: 14px;
}
.php-email-form input {
  height: 44px;
}
.php-email-form textarea {
  padding: 10px 12px;
}

.php-email-form input.form-check-input {
  height: 14px;
  margin: 0.375rem 0.75rem;
}

.php-email-form label.form-check-label {
  margin-bottom: 0px;
}

.php-email-form button[type='submit'] {
  background: #f76cc6;
  border: 0;
  padding: 10px 24px;
  color: #fff;
  transition: 0.4s;
  border-radius: 50px;
}
.php-email-form button[type='submit']:hover {
  background: #f650c1;
}

label span.required {
  position: relative;
  top: -6px;
  left: 5px;
}

.php-email-form .input-group {
  padding-bottom: 8px;
  width: inherit;
}

.php-email-form .input-group-text {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.php-email-form label span.required i.bi {
  color: red;
  font-size: 8px;
}

.php-email-form label {
  padding-left: 20px;
  margin-bottom: 5px;
}

@-webkit-keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.contact-form-wrap h2 {
  text-transform: none;
}

.contact-form-wrap {
  background: #f9f9f9;
  padding: 60px 80px;
}
@media only screen and (max-width: 991px) {
  .review-form-wrap,
  .contact-form-wrap {
    padding-left: 35px;
    padding-right: 35px;
  }
}
@media only screen and (max-width: 375px) {
  .review-form-wrap,
  .contact-form-wrap {
    padding-left: 25px;
    padding-right: 25px;
  }
}
.contact-form-wrap .rating i {
  color: rgba(0, 0, 0, 0.25);
}
.contact-form-wrap .form-control {
  border: none;
  box-shadow: 10px 4px 60px rgba(0, 0, 0, 0.1);
}

.contact-form-wrap select.form-control {
  border: none;
  box-shadow: 10px 4px 60px rgba(0, 0, 0, 0.1);
  font-weight: 600;
  color: #242424;
  font-family: 'Montserrat', sans-serif;
}

.successful-appointment {
  background: #ecf7f0;
}

.successful-appointment p {
  padding: 10px 0px;
}

.successful-appointment p strong {
  color: black;
}

.successful-appointment h6 {
  cursor: pointer;
  color: #d82f84;
}
a.contact-link {
  text-decoration: none;
  color: #333333;
}

button.submit {
  padding: 20px 30px;
  width: 200px;
  cursor: pointer;
  border: 0px;
  position: relative;
  margin: 20px;
  transition: all 0.25s ease;
  background: rgba(116, 23, 231, 1);
  color: #fff;
  overflow: hidden;
  border-radius: 10px;
}
