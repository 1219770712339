/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/**
* Desktop Navigation
*/
.navbar {
  padding: 0;
}
.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}
.navbar li {
  position: relative;
}
.navbar a,
.navbar a:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 10px 24px;
  font-size: 15px;
  font-weight: 500;
  color: #433f39;
  white-space: nowrap;
  transition: 0.3s;
}
.navbar a i,
.navbar a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}
.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover > a,
.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover > a {
  color: #f76cc6;
}

/**
  * Mobile Navigation
  */
.mobile-nav-toggle {
  color: #433f39;
  font-size: 35px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
  padding-right: 20px;
}

@media (max-width: 991px) {
  .mobile-nav-toggle {
    display: block;
  }

  .navbar ul {
    display: none;
  }
}
.navbar-mobile {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(39, 37, 34, 0.9);
  transition: 0.3s;
  z-index: 0;
}
.navbar-mobile .mobile-nav-toggle {
  position: absolute;
  top: 15px;
  right: 15px;
  color: #fff;
}
.navbar-mobile ul {
  display: block;
  position: absolute;
  top: 55px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  padding: 10px 0;
  border-radius: 8px;
  background-color: #fff;
  overflow-y: auto;
  transition: 0.3s;
}
.navbar-mobile a,
.navbar-mobile a:focus {
  padding: 10px 20px;
  font-size: 15px;
  color: #433f39;
}

.navbar a.book-a-table-btn,
.navbar-mobile a.book-a-table-btn {
  color: #fff;
  margin: 0 0 0 20px;
  padding: 10px 25px;
  font-size: 13px;
}

.navbar a.book-a-table-btn:hover,
.navbar-mobile a.book-a-table-btn:hover {
  background: #f650c1;
  color: #fff;
}
@media (max-width: 992px) {
  .navbar a.book-a-table-btn,
  .navbar-mobile a.book-a-table-btn {
    margin: 0px 0 0 15px;
    padding: 8px 20px;
    letter-spacing: 1px;
  }
}
