body {
  font-family: 'Poppins', sans-serif;
  color: #444444;
}

a {
  text-decoration: none;
  color: #f76cc6;
}

a:hover {
  color: #f650c1;
  text-decoration: none;
}

#main {
  padding-top: 150px;
}

span.primary,
span.active {
  color: #f76cc6;
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  padding: 30px 0;
}

.section-bg {
  background-color: white;
}

.section-title {
  text-align: center;
  padding-bottom: 30px;
}
.section-title h2 {
  /* margin: 40px 0 0 0; */
  font-size: 32px;
  font-weight: 700;
  color: #5f5950;
}
.section-title h2 span {
  color: #f76cc6;
}
.section-title p {
  margin: 15px auto 0 auto;
  font-weight: 300;
}
@media (min-width: 1024px) {
  .section-title p {
    width: 50%;
  }
}

a.book-a-table-btn {
  background: #f76cc6;
  color: #fff;
  border-radius: 50px;
  /* margin: 0 0 0 20px; */
  padding: 10px 25px;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 1px;
  transition: 0.3s;
  white-space: nowrap;
}

a.book-a-table-btn:hover {
  background: #f650c1;
  color: #fff;
}

.portfolio-container .portfolio-item {
  margin-bottom: 30px;
  /* width: auto; */
  /* height: auto; */
  min-height: 400px;
}
.portfolio-container {
  /* min-height: 100vh; */
}

.react-photo-album--photo {
  border-radius: 1%;
}

.box {
  padding: 30px 50px 50px 50px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  /* transition: all ease-in-out 0.3s; */
  height: 100%;
  background: #f7f7f7;
}
/* .box span {
  display: block;
  font-size: 28px;
  font-weight: 700;
  color: #f76cc6;
} */
.box h4 {
  font-size: 24px;
  font-weight: 600;
  padding: 0;
  margin: 20px 0;
}

.box .title-success h4 i,
.box .title-fail h4 i {
  font-size: 30px;
}

.box .title-success h4 {
  color: #349457;
}

.box .title-fail h4 {
  color: #a71f2c;
}

.box p {
  color: #776f6f;
  font-size: 15px;
  margin: 0;
  padding-bottom: 10px;
}

.load {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: inherit;
}

.load::after {
  content: '';
  position: absolute;
  border-radius: 50%;
  border: 3px solid #fff;
  width: 30px;
  height: 30px;
  border-left: 3px solid transparent;
  border-bottom: 3px solid transparent;
  animation: loading1 1s ease infinite;
  z-index: 10;
}

.load::before {
  content: '';
  position: absolute;
  border-radius: 50%;
  border: 3px dashed #fff;
  width: 30px;
  height: 30px;
  border-left: 3px solid transparent;
  border-bottom: 3px solid transparent;
  animation: loading1 2s linear infinite;
  z-index: 5;
}

@keyframes loading1 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

button.active {
  transform: scale(0.85);
}

button.activeLoading .loading {
  visibility: visible;
  opacity: 1;
}

button .loading {
  opacity: 0;
  visibility: hidden;
}
