#topbar {
    top: 100px;
    padding: 0;
    font-size: 17px;
    height: 50px;
    transition: all 0.5s;
    background: rgb(247 247 247);
    z-index: 996;
    color: #433f39;
}
#topbar.topbar-transparent {
    background: transparent;
}
#topbar.topbar-scrolled {
    top: -50px;
}
#topbar i {
    color: #f76cc6;
    line-height: 0;
}
#topbar i span {
    color: #433f39;
    font-style: normal;
    padding-left: 5px;
}

#topbar a img.social-logo{
    width: 25px;
    height: auto;
    margin: 0px 5px;
}

#topbar a.contact-link {
    text-decoration: none;
    color: #333333;
}



