/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact .section-title p {
  font-size: 1.2rem;
}

.contact .info-wrap {
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  padding: 30px;
}
.contact .info {
  background: #fff;
}
.contact .info label {
  padding-left: 15px;
}
.contact .info i {
  font-size: 20px;
  color: #f76cc6;
  float: left;
  width: 44px;
  height: 44px;
  background: #fff6e8;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
}
.contact .info p {
  padding: 0 0 0 60px;
  margin-bottom: 0;
  font-size: 14px;
  color: #7a7368;
}
.contact .info:hover i {
  background: #f76cc6;
  color: #fff;
}
