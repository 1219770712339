#footer {
    font-size: 14px;
    text-align: center;
    padding: 30px 0;
    background: #f7f7f7;
    color: #433f39;
}
#footer h3 {
    font-size: 36px;
    font-weight: 700;
    color: #433f39;
    position: relative;
    padding: 0;
    margin: 0 0 15px 0;
}

#footer h6 {
    margin: 10px 0px 10px 0px;
    line-height: 25px;
}

#footer p {
    font-size: 15;
    padding: 0;
    margin: 0 0 20px 0;
}

#footer .social-links a {
    font-size: 20px;
    display: inline-block;
    background: #f76cc6;
    color: #fff;
    line-height: 1;
    padding: 7px 0;
    margin-right: 4px;
    border-radius: 50%;
    text-align: center;
    width: 35px;
    height: 35px;
    transition: 0.3s;
}

@media (max-width: 991px) {
    #footer .social-links{
        padding-bottom: 20px;
    }
}
#footer .social-links a:hover {
    background: #f650c1;
}
/* #footer .copyright {
    margin: 0 0 5px 0;
} */
/* #footer .credits {
    font-size: 13px;
} */

#footer a.contact-link {
    text-decoration: none;
    color: #333333;
}

#footer .form-control {
    /* display: block; */
    min-height: calc(1.5em + 0.75rem + 2px);
    padding: 0.575rem 0.75rem;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    /* -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none; */
    border-top-left-radius: 52px;
    border-bottom-left-radius: 52px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

#footer .form-control:focus {
    border-color: #f76cc6;
    outline: 0;
    box-shadow: none;
}

#footer .border-rad {
    border-top-right-radius: 28px;
    border-bottom-right-radius: 28px;

    color: #fff;
    background-color: #f76cc6;
    border-color: #f76cc6;
}

#footer .border-rad:hover {
    background-color: #f650c1;
    border-color: #f650c1;
}


