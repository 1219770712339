#hero {
  width: 100%;
  min-height: calc(100vh - 70vh);
  max-height: calc(100vh - 150px);
  overflow: hidden;
  padding: 0;
}
#hero .carousel-item {
  width: 100%;
  min-height: calc(100vh - 70vh);
  max-height: calc(100vh - 150px);
  overflow: hidden;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
