/*--------------------------------------------------------------
# Portfolio
--------------------------------------------------------------*/
.portfolio #portfolio-flters {
  padding: 0;
  margin: 0 auto 25px auto;
  list-style: none;
  text-align: center;
  border-radius: 50px;
}
.portfolio #portfolio-flters li {
  cursor: pointer;
  display: inline-block;
  padding: 10px 15px;
  font-size: 15px;
  font-weight: 500;
  line-height: 1;
  text-transform: uppercase;
  color: #444444;
  margin-bottom: 5px;
  transition: all 0.3s ease-in-out;
  font-family: 'Poppins', sans-serif;
  background: rgba(128, 128, 128, 0.1);
  border-radius: 30px;
}
.portfolio #portfolio-flters li:hover,
.portfolio #portfolio-flters li.filter-active {
  color: #f76cc6;
}
.portfolio #portfolio-flters li:last-child {
  margin-right: 0;
}
.portfolio .portfolio-item {
  margin-bottom: 30px;
}

.btn-load-more {
  background: rgba(128, 128, 128, 0.1);
  text-transform: uppercase;
  margin: 2rem auto;
  border: 1px solid rgba(128, 128, 128, 0.1);
}

.btn-load-more:hover,
.btn-load-more:active {
  color: #f650c1;
}
